<template>
  <!-- <Header type="regular" /> -->

  <!--  -->
  <div class="layout">
    <!-- <HomeHero /> -->

    <div class="container">
      <div class="row">
        <Subheader type="registry" />
        <div class="col-12">
          <div v-if="loading" class="loading-overlay small">
            <img :src="spinner" alt="" />
          </div>
          <div class="mb-5" v-else>
            <div class="mt-5 pt-5">
              <div class="col-12">
                <h2 class="ps-md-5 ps-0 mb-4 mt-4">Registry</h2>
              </div>
              <div v-if="!metamaskAddress" class="col-12">
                <h3 class="ps-md-5 ps-0">
                  Please connect MetaMask in order to view identity information
                </h3>
              </div>
              <!-- <div v-if="notRegistered" class="col-12">
                <h3 class="ps-md-5 ps-0">
                  This account is not registered to any companies
                </h3>
              </div> -->
              <div class="no-identity-msg col-12" v-if="noIdentity">
                <p>
                  Currently connected MetaMask address (<span class="bold">{{ metamaskAddress }}</span>) hasn't been
                  registered to this company yet.
                </p>
              </div>
              <div
                v-else-if="
                  identity.length &&
                  identityType === 'personal' &&
                  metamaskAddress &&
                  !editEnabled
                "
                class="col-12 row gx-5 identity-info ps-md-5 ps-0"
              >
                <div
                  class="
                    d-flex
                    justify-content-between
                    mb-4
                    flex-row
                    align-items-end
                    mt-4
                  "
                >
                  <h3 class="mt-3 pt-3 section-heading bold">
                    Personal data ({{
                      isDecrypted ? "decrypted" : "encrypted"
                    }})
                  </h3>
                  <div
                    v-if="isDecrypted"
                    @click="toggleHideInfo"
                    class="hide-info"
                  >
                    <img :src="hide" alt="" />
                  </div>
                </div>
                <!-- <div class="col-12 col-md-6 d-flex mb-3">
                  <label>Title</label>
                  <input
                    :class="hideInfo ? 'disabled-input' : ''"
                    :disabled="!editEnabled"
                    :title="identity[0]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[0]
                        : shortenAddress(identity[0])
                    "
                  />
                </div> -->
                <div class="col-12 col-md-6 d-flex mb-3">
                  <label>First name</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[1]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[1]
                        : shortenAddress(identity[1])
                    "
                  />
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <label>Last name</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[2]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[2]
                        : shortenAddress(identity[2])
                    "
                  />
                </div>
                <!-- <div class="col-12 col-md-6 d-flex mb-3">
                  <label>Date of birth</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[3]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[3]
                        : shortenAddress(identity[3])
                    "
                  />
                </div> -->
                <div class="col-12 col-md-6 d-flex mb-3">
                  <label>Place of living</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[4]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[4]
                        : shortenAddress(identity[4])
                    "
                  />
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <label>Country of living</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[5]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[5]
                        : shortenAddress(identity[5])
                    "
                  />
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <label>Email</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[6]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[6]
                        : shortenAddress(identity[6])
                    "
                  />
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <label>Birthday</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[7]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[7]
                        : shortenAddress(identity[7])
                    "
                  />
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <label>Gender</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[10]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[10]
                        : shortenAddress(identity[10])
                    "
                  />
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <label>IBAN</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[8]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[8]
                        : shortenAddress(identity[8])
                    "
                  />
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <label>BIC</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[9]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[9]
                        : shortenAddress(identity[9])
                    "
                  />
                </div>
                <!-- <div class="col-12 col-md-6 d-flex mb-3">
                  <label>Birthday</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[11]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[11]
                        : shortenAddress(identity[11])
                    "
                  />
                </div>  -->
                <div class="col-12">
                  <button
                    :disabled="disableDecrypt"
                    class="button mt-4 w-100"
                    @click="decryptCheck"
                    v-if="!isDecrypted"
                  >
                    Decrypt data
                  </button>
                  <button
                    v-if="isDecrypted"
                    @click="enableEdit"
                    class="button mt-4 w-100"
                  >
                    Edit data
                  </button>
                </div>
              </div>

              <div
                v-if="
                  identity.length &&
                  identityType === 'corporate' &&
                  metamaskAddress &&
                  !editEnabled
                "
                class="row gx-5 identity-info ps-md-5 ps-0"
              >
                <div
                  class="
                    d-flex
                    justify-content-between
                    mb-4
                    flex-row
                    align-items-end
                    mt-4
                  "
                >
                  <h3 class="mt-3 pt-3 section-heading bold">
                    Corporate data ({{
                      isDecrypted ? "decrypted" : "encrypted"
                    }})
                  </h3>
                  <div
                    v-if="isDecrypted"
                    @click="toggleHideInfo"
                    class="hide-info"
                  >
                    <img :src="hide" alt="" />
                  </div>
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <label>Company name</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[0]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[0]
                        : shortenAddress(identity[0])
                    "
                  />
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <label>Head office address</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[1]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[1]
                        : shortenAddress(identity[1])
                    "
                  />
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <label>Postal address</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[2]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[2]
                        : shortenAddress(identity[2])
                    "
                  />
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <label>Country of living</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[3]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[3]
                        : shortenAddress(identity[3])
                    "
                  />
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <label>Email</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[4]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[4]
                        : shortenAddress(identity[4])
                    "
                  />
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <label>Registration number</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[5]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[5]
                        : shortenAddress(identity[5])
                    "
                  />
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <label>Registry code</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[6]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[6]
                        : shortenAddress(identity[6])
                    "
                  />
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <label>IBAN</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[8]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[8]
                        : shortenAddress(identity[8])
                    "
                  />
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <label>BIC</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[7]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[7]
                        : shortenAddress(identity[7])
                    "
                  />
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <label>Place of registration</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[9]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[9]
                        : shortenAddress(identity[9])
                    "
                  />
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <label>Date of registration</label>
                  <input
                    disabled
                    :class="hideInfo ? 'disabled-input' : ''"
                    :title="identity[10]"
                    :value="
                      hideInfo
                        ? hiddenMask
                        : isDecrypted
                        ? identity[10]
                        : shortenAddress(identity[10])
                    "
                  />
                </div>
                <div class="col-12">
                  <button
                    v-if="!isDecrypted"
                    :disabled="disableDecrypt"
                    class="button mt-4 w-100"
                    @click="decryptCheck"
                  >
                    Decrypt data
                  </button>
                  <button
                    v-if="isDecrypted"
                    @click="enableEdit"
                    class="button mt-4 w-100"
                  >
                    Edit data
                  </button>
                </div>
              </div>
            </div>

            <form
              v-if="
                (signature || !noIdentity) &&
                metamaskAddress &&
                isDecrypted &&
                identityType === 'personal' &&
                editEnabled
              "
              class="registry-form ps-md-5 ps-0"
              action="/"
              @submit.prevent
            >
              <div class="row gx-5">
                <div
                  class="
                    d-flex
                    justify-content-between
                    mb-4
                    flex-row
                    align-items-end
                    mt-4
                  "
                >
                  <h3 class="mt-3 pt-3 section-heading bold">
                    Edit personal data
                  </h3>
                </div>
                <!-- <div class="col-12 col-md-6 d-flex mb-3">
                  <div class="form-item">
                    <label for="title">Title</label>
                    <input
                      id="title"
                      v-model="identityForm.title"
                      type="text"
                    />
                    <span v-if="identityForm.validationErrors['title']" class="error-msg">{{identityForm.validationErrors['title']}}</span>
                  </div>
                </div> -->
                <div class="col-12 col-md-6 d-flex mb-3">
                  <div class="form-item">
                    <label for="first-name">First name</label>
                    <input
                      id="first-name"
                      v-model="identityForm.firstName"
                      type="text"
                    />
                    <span v-if="identityForm.validationErrors['firstName']" class="error-msg">{{identityForm.validationErrors['firstName']}}</span>
                  </div>
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <div class="form-item">
                    <label for="last-name">Last name</label>
                    <input
                      id="last-name"
                      v-model="identityForm.lastName"
                      type="text"
                    />
                    <span v-if="identityForm.validationErrors['lastName']" class="error-msg">{{identityForm.validationErrors['lastName']}}</span>
                  </div>
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <div class="form-item">
                    <label for="address">Address</label>
                    <input
                      id="address"
                      v-model="identityForm.placeOfLiving"
                      type="text"
                    />
                    <span v-if="identityForm.validationErrors['placeOfLiving']" class="error-msg">{{identityForm.validationErrors['placeOfLiving']}}</span>
                  </div>
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <div class="form-item">
                    <label for="country">Country of living</label>
                    <!-- <input
                      id="country"
                      v-model="identityForm.countryOfLiving"
                      type="text"
                    /> -->
                    <select v-model="identityForm.countryOfLiving" name="country" id="">
                      <option v-for="country in countries" :key="country" :value="country">{{country}}</option>
                    </select>
                    <span v-if="identityForm.validationErrors['countryOfLiving']" class="error-msg">{{identityForm.validationErrors['countryOfLiving']}}</span>
                  </div>
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <div class="form-item">
                    <label for="email">E-mail</label>
                    <input
                      id="email"
                      v-model="identityForm.email"
                      type="text"
                    />
                    <span v-if="identityForm.validationErrors['email']" class="error-msg">{{identityForm.validationErrors['email']}}</span>
                  </div>
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <div class="form-item">
                    <label for="dob">Birthday</label>
                    <datepicker
                      startingView="year"
                      inputFormat="yyyy-MM-dd"
                      v-model="identityForm.birthday"
                    ></datepicker>
                    <span v-if="identityForm.validationErrors['birthday']" class="error-msg">{{identityForm.validationErrors['birthday']}}</span>
                  </div>
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <div class="form-item">
                    <label for="gender">Gender</label>
                    <select
                      v-model="identityForm.gender"
                      name="gender"
                      id="gender"
                    >
                      <option class="d-none" value="">
                        Please choose your gender
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                    <span v-if="identityForm.validationErrors['gender']" class="error-msg">{{identityForm.validationErrors['gender']}}</span>
                  </div>
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <div class="form-item">
                    <label for="iban">IBAN</label>
                    <input id="iban" v-model="identityForm.iban" type="text" />
                    <span v-if="identityForm.validationErrors['iban']" class="error-msg"
                      >{{identityForm.validationErrors['iban']}}</span
                    >
                  </div>
                </div>
                <div class="col-12 col-md-6 d-flex mb-3">
                  <div class="form-item position-relative">
                    <label for="bic">BIC</label>
                    <input
                      @keydown="e => handleBicKeyPress(e)"
                      @input="input => handleBicInput(input)"
                      v-model="identityForm.bic"
                      class="customForm"
                      type="text"
                      placeholder="BIC"
                    />
                    <div v-if="bicList.length" class="bic-list">
                      <div :class="highlightedBic === index ? 'active' : ''" @click="handleBicSelect(bic)" :key="bic" v-for="(bic, index) of bicList">
                        {{bic}}
                      </div>
                    </div>
                    <span v-if="identityForm.validationErrors['bic']" class="error-msg"
                      >{{identityForm.validationErrors['bic']}}</span
                    >
                  </div>
                </div>
                <div class="col-12 col-lg-12 mb-3">
                  <button
                    :disabled="disableSubmit"
                    @click="handleFormSubmit('personal')"
                    type="button"
                    class="button w-100 mt-4 mb-3"
                  >
                    <div v-if="disableSubmit" class="custom-spinner"></div>
                    <span v-else>Submit</span>
                  </button>
                  <button
                    @click="editEnabled = false"
                    class="button white w-100"
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>

            <form
              v-if="
                (signature || !noIdentity) &&
                metamaskAddress &&
                isDecrypted &&
                identityType === 'corporate' &&
                editEnabled
              "
              class="registry-form ps-md-5 ps-0"
              action="/"
            >
              <div class="row gx-5">
                <div class="col-12 pt-4">
                  <h3 class="section-heading mt-3 pt-3 bold mb-4 pb-2">
                    Edit corporate identity data
                  </h3>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <div class="form-item">
                    <label for="title">Company name</label>
                    <input
                      id="title"
                      v-model="corporateForm.companyName"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <div class="form-item">
                    <label for="first-name">Head office address</label>
                    <input
                      id="first-name"
                      v-model="corporateForm.headOfficeAddress"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <div class="form-item">
                    <label for="last-name">Postal address</label>
                    <input
                      id="last-name"
                      v-model="corporateForm.postalAddress"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <div class="form-item">
                    <label for="email">Country of living</label>
                    <input
                      id="country"
                      v-model="corporateForm.country"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <div class="form-item">
                    <label for="dob">Email</label>
                    <input
                      id="email"
                      v-model="corporateForm.email"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <div class="form-item">
                    <label for="address">Registration number</label>
                    <input
                      id="address"
                      v-model="corporateForm.registrationNumber"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <div class="form-item">
                    <label for="country">Registry code</label>
                    <input
                      id="country"
                      v-model="corporateForm.registryCode"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <div class="form-item">
                    <label for="country">IBAN</label>
                    <input
                      id="country"
                      v-model="corporateForm.iban"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <div class="form-item">
                    <label for="country">BIC</label>
                    <input
                      id="country"
                      v-model="corporateForm.bic"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <div class="form-item">
                    <label for="country">Place of registration</label>
                    <input
                      id="country"
                      v-model="corporateForm.placeOfRegistration"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <div class="form-item">
                    <label for="dob">Date of registration</label>
                    <datepicker
                      inputFormat="yyyy-MM-dd"
                      v-model="corporateForm.dateOfRegistration"
                    ></datepicker>
                  </div>
                </div>
                <div class="col-12 col-lg-12">
                  <button
                    @click="handleFormSubmit('corporate')"
                    type="button"
                    class="button w-100 mt-4 mb-3"
                    :disabled="disableSubmit"
                  >
                    Submit
                  </button>
                  <button
                    @click="editEnabled = false"
                    type="button"
                    class="button white w-100 mt-4"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <Footer /> -->
</template>


<script lang="js">
import { defineComponent } from "vue";
// import HomeHero from "../../components/HomeHero.vue";
import Subheader from "../../components/Subheader.vue";
import {
    getIdentity,
    editPersonalInformation,
    editCorporateInformation,
    signNonce,
    getIdentityStatus,
    getCorporateIdentityData,
    getPersonalIdentityData,
    isValidIBANNumber
    // addNewPersonalIdentity
} from '../../main';
import { shortenAddress } from '../../helpers';
import Datepicker from "vue3-datepicker";
import config from '../../config.js';
import spinner from '../../assets/images/spinner.svg';
import hide from '../../assets/images/hide.svg';
import { ethers } from 'ethers';
import iban from 'iban';

export default defineComponent({
  name: "Home",
  components: {
    // HomeHero,
    Subheader,
    Datepicker
  },
  data() {
    return {
      countries: [],
      highlightedBic: null,
      bicList: [],
      disableSubmit: false,
      invalidIban: false,
      invalidBic: false,
      hiddenMask: '●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●',
      hide: hide,
      loading: false,
      isDecrypted: false,
      noIdentity: false,
      disableDecrypt: false,
      spinner: spinner,
      metamaskAddress: null,
      identityType: '',
      notRegistered: false,
      signature: null,
      editEnabled: false,
      hideInfo: false,
      chosenCompany: null,
      identityForm: {
          bic: '',
          birthday: new Date(),
          countryOfLiving: '',
          dateOfBirth: new Date(),
          email: '',
          firstName: '',
          gender: '',
          iban: '',
          lastName: '',
          placeOfLiving: '',
          title: '',
          validationErrors: {
              bic: '',
              birthday: '',
              countryOfLiving: '',
              dateOfBirth: '',
              email: '',
              firstName: '',
              gender: '',
              iban: '',
              lastName: '',
              placeOfLiving: '',
              title: '',
          }
      },
      corporateForm: {
        companyName: '',
        headOfficeAddress: '',
        postalAddress: '',
        country: '',
        email: '',
        registrationNumber: '',
        registryCode: '',
        placeOfRegistration: '',
        dateOfRegistration: new Date(),
        bic: '',
        iban: ''
      },
      accessToken: '',
      identity: [

      ],
    };
  },
  mounted() {
    this.getCountries();
    this.$store.watch(async state => {
      console.log(state.metamaskAddress);
      if ((!state.companies || !state.companies.length) && state.metamaskAddress) {
          this.notRegistered = true;
      }
      this.metamaskAddress = state.metamaskAddress;
      this.chosenCompany = state.chosenCompany;
    });
    this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'setChosenCompany') {
            this.isDecrypted = false;
            this.editEnabled = false;
            this.disableDecrypt = false;
        }
    });
  },
  methods: {
    getCountries() {
      fetch(`${config.API}/api/v1/countries`)
      .then(res => res.json())
      .then(data => {
        this.countries = data.data.countries;
      });
    },
    handleBicKeyPress(e) {
      if (!this.bicList.length) {
        return;
      }
      if (e.keyCode === 40) {
        if (this.highlightedBic === null || this.highlightedBic + 1 === this.bicList.length) {
          this.highlightedBic = 0;
        } else if (typeof this.highlightedBic === 'number' && this.highlightedBic + 1 < this.bicList.length) {
          this.highlightedBic++;
        }
      } else if (e.keyCode === 38) {
        if (this.highlightedBic === null || this.highlightedBic === 0) {
          this.highlightedBic = this.bicList.length - 1;
        } else if (typeof this.highlightedBic === 'number' && this.highlightedBic > 0) {
          this.highlightedBic--;
        }
      } else if (e.keyCode === 13) {
        this.handleBicSelect(this.bicList[this.highlightedBic]);
      }
    },
    handleBicSelect(value) {
      this.bicList = [];
      this.identityForm.bic = value;
    },
    handleBicInput(e) {
      if (e.target.value.length < 2) {
        return;
      }
      this.getBicList(e.target.value);
    },
    getBicList(input) {
      fetch(`${config.API}/api/v1/bic-suggestions`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bic: input
        })
      })
      .then(res => res.json())
      .then(data => {
        if (data.error || data.errors) {
          this.$toast.error(data.error);
          return;
        }
        const bicArray = [];
        for (const item in data.data.bic_suggestions) {
          bicArray.push(item);
        }
        this.bicList = bicArray.slice(0, 9);
      })
      .catch(error => {
        console.log(error);
        this.$toast.error(error);
      });
    },
    enableEdit() {
        this.editEnabled = true;
    },
    toggleHideInfo() {
      this.hideInfo = !this.hideInfo;
    },
    async checkIdentityStatus() {
        this.loading = true;
        const identityStatus = await getIdentityStatus(this.chosenCompany);
        console.log(identityStatus);
        if (identityStatus) {
            this.loading = false;
            if (!Number(identityStatus[0]) && !Number(identityStatus[1])) {
                console.log('no identity');
                this.noIdentity = true;
            } else {
                if (Number(identityStatus[0])) {
                    this.identityType = 'corporate';
                    const identity = await getCorporateIdentityData(this.chosenCompany);
                    console.log('IDENTITY DATA CORP', identity);
                    this.identity = identity;
                    this.noIdentity = false;
                } else {
                    this.identityType = 'personal';
                    const identity = await getPersonalIdentityData(this.chosenCompany);
                    console.log(identity);
                    this.identity = identity;
                    this.noIdentity = false;
                }
            }
        }
    },
    confirmSignature(challenge, signature, address) {
      const access_token = window.sessionStorage.getItem('temp_token');
      if (access_token) {
          this.decryptIdentity(access_token);
      } else {
        fetch(`${config.API}/api/signature-calculate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                challenge: challenge,
                signature: signature,
                address: address
            })
        })
        .then(res => res.json())
        .then(data => {
        window.sessionStorage.setItem('temp_token', data.temp_token)
            this.decryptIdentity(data.temp_token);
        })
        .catch(error => {
            console.log(error);
        })
      } 
    },
    shortenAddress(string) {
        return shortenAddress(string);
    },
    decryptCheck() {
        this.disableDecrypt = true;
        const checksumAddress = ethers.utils.getAddress(this.metamaskAddress);
        fetch(`${config.API}/api/signature-challenge?address=${checksumAddress}`)
        .then(res => {
          if (res.status === 404) {
            throw new Error('Address not found');
          }
          return res.json();
        })
        .then(async data => {
            const challengeExpiry = new Date(data.challenge_expiry);
            const rightNow = new Date();
            console.log(challengeExpiry, rightNow);
            const signature = await signNonce(data.challenge_uuid);
            if (signature) {
                this.signature = signature;
                this.confirmSignature(data.challenge_uuid, signature, checksumAddress);
            } else {
                this.$toast.error('An error occurred, please try again');
            }
        })
        .catch(error => {
            console.log(error);
            this.$toast.error(error);
            this.disableDecrypt = false;
        })
    },
    decryptIdentity(tempToken) {
        const reqData = [];
        for (let i = 0; i < this.identity.length; i++) {
            if (parseInt(this.identity[i].slice(0, 32)) === 0) {
                reqData.push(this.identity[i].replace('0x', '').slice(32));
            } else {
                reqData.push(this.identity[i].replace('0x', ''));
            }
        }
        fetch(`${config.API}/api/decrypt`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            body: JSON.stringify({
              data: reqData,
              temp_token: tempToken
            })
        })
        .then(res => res.json())
        .then(data => {
            this.isDecrypted = true;
            this.identity = data;
            console.log(data);
            this.corporateForm.companyName = data[0];
            this.corporateForm.headOfficeAddress = data[1];
            this.corporateForm.postalAddress = data[2];
            this.corporateForm.country = data[3];
            this.corporateForm.email = data[4];
            this.corporateForm.registrationNumber = data[5];
            this.corporateForm.registryCode = data[6];
            this.corporateForm.bic = data[7];
            this.corporateForm.iban = data[8];
            this.corporateForm.placeOfRegistration = data[9];

            
            this.identityForm.title = data[0];
            this.identityForm.firstName = data[1];
            this.identityForm.lastName = data[2];
            this.identityForm.birthday = new Date(data[3]);
            this.identityForm.placeOfLiving = data[4];
            this.identityForm.countryOfLiving = data[5];
            this.identityForm.email = data[6];
            this.identityForm.dateOfBirth = new Date(data[7]);
            this.identityForm.iban = data[8];
            this.identityForm.bic = data[9];
            this.identityForm.gender = data[10];
                        

            // this.corporateForm.dateOfRegistration = Date();
            this.personalForm = data;
            console.log('decrypt response', data);
        })
        .catch(error => {
            console.log(error);
            this.disableDecrypt = false;
        });
    },
    async validateBic(bic) {
      return fetch(`${config.API}/api/v1/bic-suggestions`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bic: bic
        })
      })
      .then(res => res.json())
      // .then(data => {
      //   console.log(data.bic_suggestions[bic]);
      //   if (!data.bic_suggestions[bic]) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // })
      // .catch(error => {
      //   console.log(error);
      //   this.$toast.error(error);
      // });
    },
    async handleFormSubmit(type) {
        this.disableSubmit = true;
        let formInvalid = false;
        if (type === 'personal') {
            this.identityForm.validationErrors = {
                bic: '',
                birthday: '',
                countryOfLiving: '',
                dateOfBirth: '',
                email: '',
                firstName: '',
                gender: '',
                iban: '',
                lastName: '',
                placeOfLiving: '',
                title: '',
            }
            const now = new Date();
            const currentYear = now.getFullYear();
            const userBirthday = this.identityForm.birthday;
            if (userBirthday.getFullYear() + 18 > currentYear) {
              this.identityForm.validationErrors.birthday = 'You must be over 18 to use this DAPP'
            }
            for (const item in this.identityForm) {
              console.log(this.identityForm[item], !this.identityForm[item].length);
              if (item === 'validationErrors') {
                break;
              }
              if (this.identityForm[item].length <= 0 && item !== 'title') {
                console.log('gotcha', item);
                formInvalid = true;
                this.identityForm.validationErrors[item] = 'This field is required';
              }
            }
            if (this.identityForm.email.length) {
              const emailValid = this.identityForm.email.match(/^\S+@\S+\.\S+$/gi);
              if (!emailValid) {
                formInvalid = true;
                this.identityForm.validationErrors['email'] = 'E-mail is not valid'
              }
            }
            console.log(this.identityForm.validationErrors);
            const ibanValid = iban.isValid(this.identityForm.iban);
            const bicRes = await this.validateBic(this.identityForm.bic); // !!this.identityForm.bic.match(/([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)/i);
            let bicValid = true;
            if (!bicRes.data.bic_suggestions[this.identityForm.bic]) {
              bicValid = false;
            }
            console.log(bicValid);
            if (!ibanValid && this.identityForm.iban.length) {
              formInvalid = true;
              this.identityForm.validationErrors['iban'] = 'IBAN is not valid'
            }
            if (!bicValid && this.identityForm.bic.length) {
              formInvalid = true;
              this.identityForm.validationErrors['bic'] = 'BIC is not valid'
            }
            if (formInvalid) {
              return;
            }
            const day = this.identityForm.birthday.getDate().toString().length < 2 ? `0${this.identityForm.birthday.getDate()}` : this.identityForm.birthday.getDate();
            const month = (this.identityForm.birthday.getMonth() + 1).toString().length < 2 ? `0${this.identityForm.birthday.getMonth() + 1}` : this.identityForm.birthday.getMonth() + 1;
            const birthday = `${this.identityForm.birthday.getFullYear()}-${month}-${day}`;
            console.log(birthday);
            const submitData = [
                this.identityForm.title, 
                this.identityForm.firstName,
                this.identityForm.lastName, 
                birthday,
                this.identityForm.placeOfLiving,
                this.identityForm.countryOfLiving,
                this.identityForm.email,
                birthday,
                this.identityForm.iban,
                this.identityForm.bic,
                this.identityForm.gender
            ];
            for (let item of submitData) {
              console.log(item.length);
            }
            const tempToken = window.sessionStorage.getItem('temp_token');
            console.log(tempToken);
            fetch(`${config.API}/api/crypt`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    data: submitData,
                    temp_token: tempToken
                })
            })
            .then(res => res.json())
            .then(async encryptedFormData => {
                console.log('encrypted form data', encryptedFormData);
                // this.disableSubmit = false;
                const res = await editPersonalInformation(encryptedFormData, this.chosenCompany);
                this.disableSubmit = false;
                if (res.hash) {
                  this.$toast.success('Personal data updated successfully.');
                } else {
                  this.$toast.error('Something went wrong, please try again.');
                }
            })
            .catch(error => {
                this.disableSubmit = false;    
                console.log(error);
            })
        } else if (type === 'corporate') {
            const day = this.corporateForm.dateOfRegistration.getDate().toString().length < 2 ? `0${this.corporateForm.dateOfRegistration.getDate()}` : this.corporateForm.dateOfRegistration.getDate();
            const month = (this.corporateForm.dateOfRegistration.getMonth() + 1).toString().length < 2 ? `0${this.corporateForm.dateOfRegistration.getMonth() + 1}` : this.corporateForm.dateOfRegistration.getMonth() + 1;
            const registrationDate = `${this.corporateForm.dateOfRegistration.getFullYear()}-${month}-${day}`;
            const submitData = [
                this.corporateForm.companyName, 
                this.corporateForm.headOfficeAddress,
                this.corporateForm.postalAddress,
                this.corporateForm.country,
                this.corporateForm.email,
                this.corporateForm.registrationNumber,
                this.corporateForm.registryCode,
                this.corporateForm.bic,
                this.corporateForm.iban,
                this.corporateForm.placeOfRegistration,
                registrationDate
            ];
            const tempToken = window.sessionStorage.getItem('temp_token');
            fetch(`${config.API}/api/crypt`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    data: submitData,
                    temp_token: tempToken
                })
            })
            .then(res => res.json())
            .then(async encryptedCorporateData => {
                console.log('encrypted', encryptedCorporateData);
                const editIdentityRes = await editCorporateInformation(encryptedCorporateData, this.chosenCompany);
                if (editIdentityRes.hash) {
                  this.$toast.success('Corporate data updated successfully.')
                } else {
                  this.$toast.error('Something went wrong, please try again.');
                }
                this.disableSubmit = false;
            })
            .catch(error => {
                this.disableSubmit = false;
                console.log(error);
            });
        }
    },
    handleNewPersonalIdentity() {
        // const formData = this.personalForm;
        // addNewPersonalIdentity(formData); //probably remove
    }
  },
  watch: {
    // metamaskAddress: function(newValue) {
    //   if (newValue) {
    //     this.checkIdentityStatus();
    //   }
    // },
    chosenCompany: function(newValue) {
      this.checkIdentityStatus(newValue);
    },
    'identityForm.iban': function() {
      this.invalidIban = false;
    },
    'corporateForm.iban': function() {
      this.invalidIban = false;
    },
    'identityForm.bic': function() {
      this.invalidBic = false;
    },
    'corporateForm.bic': function() {
      this.invalidBic = false;
    }
  }
});
</script>
